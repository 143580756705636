@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;500;600;700&display=swap");

html {
  font-family: "Epilogue", sans-serif;
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

input:focus {
  outline: none !important;
}

nav {
  flex-grow: 1;
}

body {
  margin: 0;
  letter-spacing: 0.25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  position: relative;
  padding-top: 3.8rem;
}

/* Scrollbar */
.scrollable::-webkit-scrollbar {
  width: 0.35rem;
  height: 0.35rem;
}

.scrollable::-webkit-scrollbar-track {
  border-radius: 1rem;
  background: #f9ffdf;
  margin: 0.5rem;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #13173d;
  border-radius: 0.5rem;
  padding-top: 4rem;
}

/* Map */
.leaflet-control-container {
  position: absolute;
  bottom: 3rem;
  right: 1rem;
  width: 2rem;
  height: 3rem;
}

.leaflet-bar a {
  background-color: #ccff00 !important;
}

.leaflet-bar a:hover,
.leaflet-bar a:focus {
  background-color: #b0dc00 !important;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-left .leaflet-control {
  margin: 0 !important;
}

div.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-touch .leaflet-bar a:first-child {
  width: 2rem;
  border-radius: 0.5rem !important;
  height: 2rem;
  margin-bottom: 0.5rem;
}

.leaflet-touch .leaflet-bar a:last-child {
  width: 2rem;
  border-radius: 0.5rem !important;
  height: 2rem;
}

div a.leaflet-control-zoom-in,
div a.leaflet-control-zoom-out {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

div a.leaflet-control-zoom-in {
  background-image: url("assets/images/map/plus.svg");
}

div a.leaflet-control-zoom-out {
  background-image: url("assets/images/map/minus.svg");
}

.leaflet-control-zoom-in span,
.leaflet-control-zoom-out span {
  color: transparent;
}

.leaflet-bar a.leaflet-disabled {
  display: none;
}

/* Home background */
#home article {
  background: linear-gradient(
    0deg,
    rgba(19, 23, 61, 0.8) 0%,
    rgba(19, 23, 61, 0.8) 100%
  );
}

/* Explore arrow */
.cards:nth-child(odd):hover .explore span path {
  fill: #ccff00;
}

.cards:nth-child(even):hover .explore span path {
  fill: #0007e0;
}

/* Select options */
#menu- li:focus {
  background: #ccff00;
}

#menu- li:hover:not(:focus) {
  background: #ecffa0;
}
@media (max-width: 768px) {
  #menu- li {
    min-height: 2.5rem;
    font-size: 0.8rem;
  }
}

.overlay {
  background: linear-gradient(
    0deg,
    rgba(174, 181, 244, 0.8) 0%,
    rgba(174, 181, 244, 0.8) 100%
  );
}
